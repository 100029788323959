import axios from 'axios';
import { useAuth } from './AuthModule';

const SPREADSHEET_ID = '11oT5AFb1y_q6Qs28Hg61TMk9gS7x_LUVHItLEAnbejw';

export const useSpreadsheet = () => {
  const { accessToken } = useAuth();

  const updateSpreadsheet = async (rowIndex: number, columnC: string, columnD: string, columnE: string) => {
    try {
      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/C${rowIndex}:E${rowIndex}?valueInputOption=USER_ENTERED`,
        {
          values: [[columnC, columnD, columnE]]
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error updating spreadsheet:', error);
    }
  };

  return { updateSpreadsheet };
};