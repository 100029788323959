
// MessageModule.tsx
import { useState } from 'react';

export interface Message {
  text: string;
  isUser: boolean;
}

export const useMessages = () => {
  const [messages, setMessages] = useState<Message[]>([]);

  const addMessage = (text: string, isUser: boolean) => {
    setMessages(prevMessages => [...prevMessages, { text, isUser }]);
  };

  return { messages, addMessage, setMessages };
};