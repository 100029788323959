// App.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useAuth, AuthProvider } from './AuthModule';
import { useTranslations } from './TranslationModule';
import { useMessages } from './MessageModule';
import { useSpreadsheet } from './SpreadsheetModule';
import { findDifferences } from './TextDiffModule';
import './App.css';

const App: React.FC = () => {
  const { accessToken, handleLogin, handleLogout } = useAuth();
  const { translations, currentIndex, fetchTranslations, setCurrentIndex, setTranslations, isRetryMode, setIsRetryMode } = useTranslations();
  const { messages, addMessage, setMessages } = useMessages();
  const { updateSpreadsheet } = useSpreadsheet();
  const [userInput, setUserInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [attemptCount, setAttemptCount] = useState<number>(0);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [shouldShowQuestion, setShouldShowQuestion] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const handleFetchError = (error: any) => {
    console.error("Error fetching translations:", error);
    if (error.response && error.response.status === 401) {
      handleLogout();
    }
  };

  useEffect(() => {
    if (accessToken && !isInitialized) {
      fetchTranslations(false).catch(handleFetchError);
      addMessage("ようこそ！英語翻訳チャットを開始します。", false);
      addMessage("前回の続きから始めます。最初から始める場合は、「Retry」を送信してください。", false);
      setIsLoading(false);
      setIsInitialized(true);
    }
  }, [accessToken, addMessage, fetchTranslations, isInitialized]);

  useEffect(() => {
    if (translations.length > 0 && currentIndex < translations.length && isInitialized) {
      setShouldShowQuestion(true);
    }
  }, [translations, currentIndex, isInitialized]);

  useEffect(() => {
    if (shouldShowQuestion) {
      addMessage(`日本語: ${translations[currentIndex].japanese}\n残りの問題数: ${translations.length - currentIndex}`, false);
      setShouldShowQuestion(false);
    }
  }, [shouldShowQuestion, translations, currentIndex, addMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    if (userInput.toLowerCase().trim() === 'retry') {
      addMessage("Retry", true);
      addMessage("復習モードを開始します。", false);
      setUserInput('');
      setIsRetryMode(true);
      await fetchTranslations(true).catch(handleFetchError);
      return;
    }

    if (userInput.toLowerCase().trim() === 'reset') {
      addMessage("Reset", true);
      addMessage("ログアウトします。再度ログインしてください。", false);
      handleLogout();
      setUserInput('');
      return;
    }

    addMessage(userInput, true);

    if (translations.length === 0 || currentIndex >= translations.length) {
      addMessage("問題が利用できません。新しい問題セットを取得します。", false);
      await fetchTranslations(false).catch(handleFetchError);
      return;
    }

    const currentTranslation = translations[currentIndex];

    if (!currentTranslation) {
      addMessage("問題の取得に失敗しました。もう一度お試しください。", false);
      return;
    }
  
    setAttemptCount(prev => prev + 1);

    const isCorrect = userInput.toLowerCase().trim() === currentTranslation.english.toLowerCase();
    const containsCorrectAnswer = currentTranslation.english.toLowerCase().includes(userInput.toLowerCase().trim());
    
    let columnC = currentTranslation.flagC || '';
    if (isCorrect) {
      if ((!isRetryMode && attemptCount === 0) || (isRetryMode && attemptCount <= 1)) {
        columnC = '1';
      }
    }

    let columnD = parseInt(currentTranslation.flagD || '0');
    columnD += attemptCount;
    if (isCorrect) {
      columnD += 1;
    }

    const timestamp = isRetryMode ? new Date().toISOString() : '';
    await updateSpreadsheet(currentTranslation.rowIndex, columnC, columnD === 0 ? '' : columnD.toString(), timestamp);

    if (isCorrect) {
      addMessage("正解です！次の問題に進みましょう。", false);

      setAttemptCount(0);

      if (currentIndex + 1 < translations.length) {
        setCurrentIndex(prevIndex => prevIndex + 1);
      } else {
        addMessage("全ての問題が終了しました。「Retry」を入力すると復習します。", false);
      }
    } else {
      const differences = findDifferences(userInput, currentTranslation.english);
      addMessage(`不正解です。以下が正解との差分です：`, false);
      addMessage(differences, false);
      addMessage(`正解: ${currentTranslation.english}`, false);
      if (containsCorrectAnswer) {
        addMessage("正解を含む回答でした。", false);
      }
      addMessage(`もう一度挑戦してください。\n日本語訳: ${currentTranslation.japanese}\n残りの問題数: ${translations.length - currentIndex}`, false);
    }
  
    setUserInput('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\u2019/g, "'");
    setUserInput(value);
  };

  if (!accessToken) {
    return (
      <div className="App">
        <header className="App-header">
          <h1>英語翻訳チャット</h1>
        </header>
        <button onClick={handleLogin}>Google アカウントを選択してログイン</button>
      </div>
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>英語翻訳チャット</h1>
      </header>
      <div className="chat-container" ref={chatContainerRef}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.isUser ? 'message-user' : 'message-system'}`}>
            <span 
              className="message-content" 
              dangerouslySetInnerHTML={{ __html: message.text }}
            />
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder="英語で入力してください"
          className="input-field"
        />
        <button type="submit" className="submit-button">送信</button>
      </form>
    </div>
  );
};

const AppWithAuth: React.FC = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWithAuth;