import { useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthModule';
import { useMessages } from './MessageModule';

const SPREADSHEET_ID = '11oT5AFb1y_q6Qs28Hg61TMk9gS7x_LUVHItLEAnbejw';

interface Translation {
  japanese: string;
  english: string;
  rowIndex: number;
  flagC?: string;
  flagD?: string;
  flagE?: string;
}

export const useTranslations = () => {
  const [translations, setTranslations] = useState<Translation[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isRetryMode, setIsRetryMode] = useState<boolean>(false);
  const { accessToken } = useAuth();
  const { addMessage } = useMessages();

  const isEmpty = (value: string | undefined): boolean => {
    return value === undefined || value === null || value.trim() === '';
  };

  const fetchTranslations = async (isRetry: boolean) => {
    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/A:E`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      const records = response.data.values as string[][];
      
      console.log('Raw data from spreadsheet:', records);
  
      const mapTranslations = () => {
        return records.slice(1)
          .map((record: string[], index: number) => ({
            japanese: record[0],
            english: record[1],
            rowIndex: index + 2,
            flagC: record[2],
            flagD: record[3],
            flagE: record[4]
          }))
          .filter(translation => translation.japanese && translation.english);
      };
  
      const allTranslations = mapTranslations();

      if (isRetry) {
        const retryTranslations = allTranslations.filter(t => isEmpty(t.flagC));
        setTranslations(retryTranslations);
        setCurrentIndex(0);
        setIsRetryMode(true);
        addMessage(`復習モードを開始します。全${retryTranslations.length}問です。`, false);
        if (retryTranslations.length > 0) {
          addMessage(`日本語: ${retryTranslations[0].japanese}\n残りの問題数: ${retryTranslations.length}`, false);
          console.log('Starting retry mode:', retryTranslations[0]);
        }
        return;
      }

      // 通常モード: C列とD列が空の問題をフィルタ
      let newTranslations = allTranslations.filter(t => isEmpty(t.flagC) && isEmpty(t.flagD));

      if (newTranslations.length > 0) {
        console.log('通常モードの問題数:', newTranslations.length);
        addMessage(`通常モードの問題数: ${newTranslations.length}`, false);
        setTranslations(newTranslations);
        setCurrentIndex(0);
        setIsRetryMode(false);
        addMessage(`通常モードで開始します。全${newTranslations.length}問です。`, false);
        addMessage(`日本語: ${newTranslations[0].japanese}\n残りの問題数: ${newTranslations.length}`, false);
        console.log('First question:', newTranslations[0]);
      } else {
        // 通常モードの問題がない場合、復習モードを開始
        console.log('通常モードの問題がありません。復習モードを開始します。');
        addMessage("通常モードの問題がありません。復習モードを開始します。", false);
        
        // C列が空の問題をフィルタリング
        const retryTranslations = allTranslations.filter(t => isEmpty(t.flagC));
        
        // 最新のタイムスタンプを持つ問題のインデックスを見つける
        const latestTimestampIndex = retryTranslations.reduce((maxIndex, translation, currentIndex, arr) => {
          if (!translation.flagE) return maxIndex;
          if (maxIndex === -1) return currentIndex;
          return new Date(translation.flagE) > new Date(arr[maxIndex].flagE) ? currentIndex : maxIndex;
        }, -1);

        if (latestTimestampIndex !== -1) {
          console.log('最新のタイムスタンプが見つかりました。位置:', latestTimestampIndex);
          addMessage(`前回の続きから始めます。`, false);
          newTranslations = retryTranslations.slice(latestTimestampIndex);
        } else {
          newTranslations = retryTranslations;
        }

        setTranslations(newTranslations);
        setCurrentIndex(0);
        setIsRetryMode(true);
        addMessage(`復習モードで開始します。全${newTranslations.length}問です。`, false);
        if (newTranslations.length > 0) {
          addMessage(`日本語: ${newTranslations[0].japanese}\n残りの問題数: ${newTranslations.length}`, false);
        }
      }
        
      console.log(`取得された問題数: ${newTranslations.length}`);
      console.log('全ての取得された問題:', newTranslations);
  
    } catch (error) {
      console.error('Error fetching translations:', error);
      addMessage("問題の取得中にエラーが発生しました。", false);
    }
  };

  return { translations, currentIndex, fetchTranslations, setCurrentIndex, setTranslations, isRetryMode, setIsRetryMode };
};