const longestCommonSubsequence = (arr1: string[], arr2: string[]): string[] => {
  const m = arr1.length;
  const n = arr2.length;
  const dp: number[][] = Array(m + 1).fill(null).map(() => Array(n + 1).fill(0));

  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (arr1[i - 1] === arr2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
  }

  const lcs: string[] = [];
  let i = m, j = n;
  while (i > 0 && j > 0) {
    if (arr1[i - 1] === arr2[j - 1]) {
      lcs.unshift(arr1[i - 1]);
      i--;
      j--;
    } else if (dp[i - 1][j] > dp[i][j - 1]) {
      i--;
    } else {
      j--;
    }
  }

  return lcs;
};

export const findDifferences = (userInput: string, correctAnswer: string): string => {
  const userWords = userInput.split(' ');
  const correctWords = correctAnswer.split(' ');

  const lcs = longestCommonSubsequence(userWords, correctWords);
  
  let userIndex = 0;
  let correctIndex = 0;
  let lcsIndex = 0;
  let result = '';

  while (userIndex < userWords.length || correctIndex < correctWords.length) {
    if (lcsIndex < lcs.length && userWords[userIndex] === lcs[lcsIndex] && correctWords[correctIndex] === lcs[lcsIndex]) {
      result += userWords[userIndex] + ' ';
      userIndex++;
      correctIndex++;
      lcsIndex++;
    } else if (userIndex < userWords.length && (lcsIndex >= lcs.length || userWords[userIndex] !== lcs[lcsIndex])) {
      result += `<del>${userWords[userIndex]}</del> `;
      userIndex++;
    } else if (correctIndex < correctWords.length && (lcsIndex >= lcs.length || correctWords[correctIndex] !== lcs[lcsIndex])) {
      result += `<strong><em>${correctWords[correctIndex]}</em></strong> `;
      correctIndex++;
    }
  }

  return result.trim();
};