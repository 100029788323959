// AuthModule.tsx

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

const CLIENT_ID = '368174254241-g40088pq6lg6vk6egoo6lf3jgslsdcu4.apps.googleusercontent.com';
//const REDIRECT_URI = 'http://localhost:3000'; // あなたのアプリケーションのコールバックURIに変更してください
const REDIRECT_URI = 'https://dp-xr-42ec4.web.app';
const SCOPES = [
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.readonly',
  'https://www.googleapis.com/auth/spreadsheets',
  'https://www.googleapis.com/auth/spreadsheets.readonly'
];

interface AuthContextType {
  accessToken: string | null;
  handleLogin: () => void;
  handleLogout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const token = params.get('access_token');

    if (token) {
      setAccessToken(token);
      localStorage.setItem('accessToken', token);
      window.history.pushState("", document.title, window.location.pathname);
    } else {
      const savedToken = localStorage.getItem('accessToken');
      if (savedToken) {
        setAccessToken(savedToken);
      }
    }
  }, []);

  const handleLogin = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${CLIENT_ID}&` +
      `redirect_uri=${encodeURIComponent(window.location.origin)}&` +
      `response_type=token&` +
      `scope=${encodeURIComponent(SCOPES.join(' '))}&` +
      `access_type=online&` +
      `prompt=select_account&` +
      `include_granted_scopes=true&` +
      `login_hint=&` +
      `state=${encodeURIComponent(JSON.stringify({ from: 'login' }))}`;
  
    window.location.href = authUrl;
  };

  const handleLogout = () => {
    setAccessToken(null);
    localStorage.removeItem('accessToken');
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ accessToken, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};